import { getDataFromApi, postDataToApi } from "./api";

/**
 * Get an appraisal based on opcoId, siteId and interventionId.
 *
 * @param {string} opcoId // The OpCo ID.
 * @param {string} siteId // The site ID.
 * @param {string} interventionId // The intervention ID.
 */
export async function getAppraisalData(opcoId, siteId, interventionId) {
  let url = `/interventions?opcoId=${opcoId}&siteId=${siteId}`;

  if (interventionId) {
    url = url + `&interventionId=${interventionId}`;
  }

  return await getDataFromApi(url, {
    headers: { "Content-Type": "application/json" },
  }).then((res) => {
    return res.json();
  });
}

/**
 * Post an appraisal to the backend.
 *
 * @param {Object} body // The post body.
 */
export async function postAppraisalData(body) {
  await postDataToApi(
    "/interventions",
    { headers: { "Content-Type": "application/json" } },
    body
  ).then((res) => {
    if (res.ok) {
      return res;
    } else {
      return res.json();
    }
  });
}
