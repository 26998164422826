<script setup>
import { STORYBLOK_VERSION_PARAM } from "../config/constants";
import { useStoryblok } from "./../hooks/useStoryblok";
import {
  readDataFromLocalStorage,
  removeDataFromLocalStorage,
} from "./../utils/utils";
</script>

<template>
  <div class="site-selector pt-2">
    <span>{{ $t("nowAppraising") }}{{ " " }}</span>
    <span>{{ opcoName }}</span> -
    <span>{{ opcoSite }}</span>
    <button
      class="buttonLink ml-2 text-primary text-decoration-underline font-weight-bold rounded outline-focus"
      @click="changeAppraisalSite()"
      name="Change aprraisal location"
    >
      {{ $t("change") }}
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedOpCo: null,
      selectedSite: null,
      opcoName: null,
      opcoSite: null,
    };
  },
  created() {
    const selectedOpCoId = readDataFromLocalStorage("selectedOpCo", "uuid");
    const selectedSiteId = readDataFromLocalStorage("selectedSite", "uuid");
    if (
      typeof selectedOpCoId !== "undefined" ||
      typeof selectedSiteId !== "undefined"
    ) {
      this.selectedOpCo = selectedOpCoId;
      this.selectedSite = selectedSiteId;
      this.fetchOpCoData();
    }
  },
  methods: {
    async fetchOpCoData() {
      const options = new URLSearchParams({
        version: STORYBLOK_VERSION_PARAM,
        find_by: "uuid",
      });
      const { data: opcoData } = await useStoryblok(
        `${this.selectedOpCo}/?${options.toString()}`
      ).json();

      const { data: siteData } = await useStoryblok(
        `${this.selectedSite}/?${options.toString()}`
      ).json();

      this.opcoName = opcoData.value.story.name;
      this.opcoSite = siteData.value.story.name;
    },
    changeAppraisalSite() {
      removeDataFromLocalStorage("selectedOpCo", "uuid");
      removeDataFromLocalStorage("selectedSite", "uuid");

      // Redirect user to the site selection page.
      this.$router.push({
        path: "/site-select",
        query: { redirectTo: this.$route.fullPath },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.site-selector {
  font-size: 14px;
  width: auto;
}
.buttonLink:hover {
  color: rgb(var(--v-theme-secondary)) !important;
}
</style>
