<script setup>
import { useStoryblok } from "../hooks/useStoryblok";
import PageSidebar from "../components/general/PageSidebar.vue";
import { STORYBLOK_VERSION_PARAM } from "../config/constants";
import Title from "../components/general/Title.vue";
</script>

<template>
  <Title :title="page.story.name" />
  <v-container>
    <v-alert
      v-if="error"
      type="error"
      :title="i18next.t('errorTitle')"
      :text="i18next.t('loadingError')"
    />
    <v-row v-else>
      <v-col cols="12" sm="3" class="d-flex flex-column">
        <PageSidebar
          :title="items[0].name"
          :items="items"
          :isChecklistPage="false"
        />
      </v-col>
      <v-col cols="12" sm="9" class="d-flex">
        <v-card elevation="0" class="w-100 py-6 px-3" id="page__content">
          <v-row>
            <v-col cols="12" md="6">
              <v-card-subtitle class="pt-3 font-weight-bold card__subtitle">
                {{ page.rels[0].name }}
              </v-card-subtitle>
              <v-card-title>
                <h1 class="text-h5 font-weight-bold">
                  {{ page.story.name }}
                </h1>
              </v-card-title>
            </v-col>
            <v-col
              v-if="icons?.length > 0"
              cols="12"
              md="6"
              class="order-first order-md-last"
            >
              <div class="icons pt-3 d-flex justify-end">
                <div
                  class="icons__single d-flex justify-center align-center rounded-circle"
                  :class="[icon.active ? 'bg-warning' : 'bg-mediumGray']"
                  v-for="icon in icons"
                  :key="icon.title"
                >
                  <img class="w-100 h-100" :src="icon.icon" :alt="icon.title" />
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-card-text class="py-0 page__content">
                <StoryblokComponent
                  v-for="blok in page.story.content.body"
                  :blok="blok"
                  :key="blok._uid"
                />
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      page: null,
      icons: [],
      items: [],
      error: false,
      errorRelatedPages: false,
    };
  },
  created() {
    // watch the params of the route to fetch the data again.
    this.$watch(
      () => this.$route.params,
      () => {
        this.page = null;
        this.fetchData();
      },
      // fetch the data when the view is created and the data is
      // already being observed.
      { immediate: true }
    );
  },
  methods: {
    async fetchData() {
      const path = window.location.pathname;

      // Get data for the current page.
      const options = new URLSearchParams({
        version: STORYBLOK_VERSION_PARAM,
        resolve_relations: "page.pageCategory",
      });

      const { error, data } = await useStoryblok(
        `${path}?${options.toString()}`
      ).json();

      const relatedPagesOptions = new URLSearchParams({
        version: STORYBLOK_VERSION_PARAM,
        starts_with: path.split("/")[1], // Get the category.
        content_type: "page",
        resolve_relations: "page.pageCategory,page.userGroup",
      });

      const { error: errorRelatedPages, data: relatedPages } =
        await useStoryblok(`/?${relatedPagesOptions.toString()}`).json();

      const items = [];
      const icons = [];
      relatedPages.value.rels
        .filter(
          (category) => category.full_slug.startsWith("user-groups/") !== true
        )
        .map((category) => {
          items.push({
            name: category.name,
            uuid: category.uuid,
            children: [],
          });
        });

      relatedPages.value.stories.map((story) => {
        const activePage = path.includes(story.full_slug) ? true : false;

        // Get the index of the object with the same category.
        const index = items.findIndex(
          (el) => el.uuid === story.content?.pageCategory
        );

        items[index].children.push({
          name: story.name,
          target: "/" + story.full_slug,
          active: activePage,
        });

        if (
          data.value.story.content.pageCategory === story.content.pageCategory
        ) {
          if (story.content?.pageIcon?.filename) {
            // Only add icon when an icon is available.
            icons.push({
              icon: story.content?.pageIcon?.filename,
              title: story.name,
              active: activePage,
            });
          }
        }
      });

      this.error = error;
      this.errorRelatedPages = errorRelatedPages;
      this.page = data.value;
      this.items = items;
      this.icons = icons;
    },
  },
};
</script>

<style scoped lang="scss">
.icons {
  &__single {
    height: 32px;
    width: 32px;
    margin-right: 10px;
  }
}
</style>
