import { createFetch } from "@vueuse/core";
import { API_ENDPOINT } from "../config/constants";
import { oktaAuth } from "../okta";

/**
 * custom useFetch hook
 * needed because there wasn't an obvious way to pass the Authorization token in @storyblok/vue
 */
export const useStoryblok = createFetch({
  baseUrl: API_ENDPOINT,
  options: {
    async beforeFetch({ options }) {
      const myToken = await oktaAuth.getIdToken();
      options.headers.Authorization = `Bearer ${myToken}`;
      return { options };
    },
  },
  fetchOptions: {
    // see: https://developer.mozilla.org/en-US/docs/Web/API/Request/mode
    mode: "cors",
  },
});
