<script setup>
import { RouterView } from "vue-router";
import Layout from "./components/general/Layout.vue";
</script>

<template>
  <v-app>
    <Layout :activeUser="activeUser">
      <Suspense>
        <template #default>
          <RouterView />
        </template>
        <template #fallback>
          <div>Loading...</div>
        </template>
      </Suspense>
    </Layout>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      activeUser: null,
    };
  },
  async created() {
    await this.refreshActiveUser();
  },
  watch: {
    // everytime a route is changed refresh the activeUser
    $route: "refreshActiveUser",
  },
  methods: {
    async refreshActiveUser() {
      this.activeUser = await this.$auth.getUser();
    },
  },
};
</script>
