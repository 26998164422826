export const ponAccessabilityTheme = {
  dark: false,
  colors: {
    background: "#FFFFFF",
    surface: "#FFFFFF",
    primary: "#002c47",
    secondary: "#00c1de",
    error: "#de0000",
    success: "#48a23f",
    warning: "#fcc400",
    lightGray: "#ebf2f2",
    mediumGray: "#c7c7c7",
    darkGray: "#bbbbbb",
    white: "#FFFFFF",
  },
};
