import { createRouter, createWebHistory } from "vue-router";
import { LoginCallback, navigationGuard } from "@okta/okta-vue";

import HomePage from "../pages/HomePage.vue";
import DefaultTemplate from "../pages/DefaultTemplate.vue";
import ChecklistPage from "../pages/ChecklistPage.vue";
import RedirectTemplate from "../pages/RedirectTemplate.vue";
import SiteSelectPage from "../pages/SiteSelectPage.vue";
import ReportPage from "../pages/ReportPage.vue";

const routes = [
  {
    path: "/report",
    component: ReportPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/checklist",
    component: RedirectTemplate,
    meta: {
      requiresAuth: true,
      contentType: "intervention",
      contentCategory: "locationCategory",
    },
  },
  {
    path: "/checklist/:location",
    component: RedirectTemplate,
    meta: {
      requiresAuth: true,
      contentType: "intervention",
      contentCategory: "locationCategory",
    },
  },
  {
    path: "/checklist/:location/:intervention",
    component: ChecklistPage,
    meta: { requiresAuth: true },
  },
  { path: "/", component: HomePage, meta: { requiresAuth: true } },
  {
    path: "/:section/:slug",
    component: DefaultTemplate,
    meta: { requiresAuth: true },
  },
  {
    path: "/:section",
    component: RedirectTemplate,
    meta: {
      requiresAuth: true,
      contentType: "page",
      contentCategory: "pageCategory",
    },
  },
  {
    path: "/site-select",
    component: SiteSelectPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login/callback",
    component: LoginCallback,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach(navigationGuard);

export default router;
