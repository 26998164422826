<script setup>
import i18n from "i18next";

import { readDataFromLocalStorage } from "../utils/utils";
import { getAppraisalData } from "../dao/appraisal";
import { useStoryblok } from "../hooks/useStoryblok";
import {
  STORYBLOK_VERSION_PARAM,
  DOES_NOT_APPLY,
  TO_DO,
} from "../config/constants";
import ReportTable from "../components/report/ReportTable.vue";
import Title from "../components/general/Title.vue";
</script>

<template>
  <Title :title="$t('report')" />
  <v-container>
    <v-row class="mb-4">
      <v-col cols="12">
        <h2 class="mb-4">
          <i18next :translation="$t('reportFor')">
            <template #opco>{{ opcoName }}</template>
            <template #location>- {{ opcoSite }}</template>
          </i18next>
        </h2>
        <router-link
          :to="lastPath ? lastPath : '/checklist'"
          class="back-button pa-2 text-primary text-decoration-none bg-lightGray rounded"
          aria-label="Link page back"
        >
          <v-icon icon="mdi-chevron-left" />
          {{ $t("back") }}
        </router-link>
      </v-col>
    </v-row>
    <ReportTable
      v-if="todoItems.length > 0"
      :title="i18n.t('todo')"
      :appraisals="todoItems"
      color="orange"
    />
    <ReportTable
      v-if="doneItems.length > 0"
      :title="i18n.t('done')"
      :appraisals="doneItems"
      color="green"
    />
    <ReportTable
      v-if="doesNotApplyItems.length > 0"
      :title="i18n.t('doesnotapply')"
      :appraisals="doesNotApplyItems"
      color="gray"
    />
  </v-container>
</template>

<script>
export default {
  data: () => ({
    todoItems: [],
    doneItems: [],
    doesNotApplyItems: [],
    opcoName: null,
    opcoSite: null,
    lastPath: null,
  }),
  created() {
    this.lastPath = this.$router.options.history.state.back;
    const selectedOpCoId = readDataFromLocalStorage("selectedOpCo", "uuid");
    const selectedSiteId = readDataFromLocalStorage("selectedSite", "uuid");

    if (
      typeof selectedOpCoId === "undefined" ||
      typeof selectedSiteId === "undefined"
    ) {
      // Redirect user to the site selection page when no OpCo/Site has been selected.
      this.$router.push({
        path: "/site-select",
        query: { redirectTo: this.$route.fullPath },
      });
    }

    this.fetchReport(selectedOpCoId, selectedSiteId);
    this.fetchOpCoData(selectedOpCoId, selectedSiteId);
  },
  methods: {
    sortItems(a, b) {
      if (a.locations[0].label < b.locations[0].label) {
        return -1;
      }
      if (a.locations[0].label > b.locations[0].label) {
        return 1;
      }
      return 0;
    },
    async fetchReport(selectedOpCoId, selectedSiteId) {
      await getAppraisalData(selectedOpCoId, selectedSiteId).then((res) => {
        const todoItems = [];
        const doneItems = [];
        const doesNotApplyItems = [];

        res.rows.map((appraisal) => {
          if (appraisal.currentSituation === DOES_NOT_APPLY) {
            doesNotApplyItems.push(appraisal);
          } else if (appraisal.currentSituation === TO_DO) {
            todoItems.push(appraisal);
          } else {
            doneItems.push(appraisal);
          }
        });

        doesNotApplyItems.sort(this.sortItems);
        todoItems.sort(this.sortItems);
        doneItems.sort(this.sortItems);

        this.todoItems = todoItems;
        this.doneItems = doneItems;
        this.doesNotApplyItems = doesNotApplyItems;
      });
    },
    async fetchOpCoData(selectedOpCoId, selectedSiteId) {
      const options = new URLSearchParams({
        version: STORYBLOK_VERSION_PARAM,
        find_by: "uuid",
      });
      const { data: opcoData } = await useStoryblok(
        `${selectedOpCoId}/?${options.toString()}`
      ).json();

      const { data: siteData } = await useStoryblok(
        `${selectedSiteId}/?${options.toString()}`
      ).json();

      this.opcoName = opcoData.value.story.name;
      this.opcoSite = siteData.value.story.name;
    },
  },
};
</script>
