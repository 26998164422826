<script setup>
import { useStoryblok } from "./../../hooks/useStoryblok";
import PonLogo from "../../icons/PonLogo.vue";
import PonLogoMobile from "../../icons/PonLogoMobile.vue";
import MenuItem from "./MenuItem.vue";
import { STORYBLOK_VERSION_PARAM } from "../../config/constants";

defineProps({ activeUser: Object });
</script>

<template>
  <div class="visually-hidden-focusable bg-secondary">
    <v-container>
      <a href="#content">{{ $t("skip") }}</a>
    </v-container>
  </div>
  <nav
    class="bg-primary nav nav__desktop hidden-sm-and-down"
    aria-label="Navigation"
    id="header"
  >
    <div>
      <router-link to="/" class="logo-focusable" aria-label="Link to home page">
        <PonLogo class="d-md-block" alt="Pon Accessibility Toolkit logo" />
      </router-link>
    </div>
    <ul aria-label="Navigation items">
      <MenuItem
        v-for="item in menuItems"
        :key="item._uid"
        :title="item.name"
        :url="item.link.url"
        class="mx-4"
      />
    </ul>
  </nav>
  <nav
    class="bg-primary nav nav__mobile hidden-md-and-up"
    aria-label="Mobile navigation"
  >
    <div>
      <router-link to="/" class="logo-focusable" aria-label="Link to home page">
        <PonLogoMobile
          class="d-block d-md-none"
          alt="Pon Accessibility Toolkit logo"
        />
      </router-link>
    </div>
    <span>
      <span>Menu</span
      ><button
        type="button"
        class="v-btn v-btn--icon v-theme--ponAccessabilityTheme v-btn--density-default v-btn--size-default v-btn--variant-text v-app-bar-nav-icon"
        aria-label="Hamburger menu toggle"
        @click="drawer = !drawer"
      >
        <span class="v-btn__overlay"></span><span class="v-btn__underlay"></span
        ><span class="v-btn__content"
          ><i
            class="mdi-menu mdi v-icon notranslate v-theme--ponAccessabilityTheme v-icon--size-default"
            aria-hidden="true"
          ></i
        ></span>
      </button>
    </span>
    <ul
      aria-label="Navigation items"
      class="bg-primary"
      :class="{ open: drawer }"
    >
      <MenuItem
        v-for="item in menuItems"
        :key="item._uid"
        :title="item.name"
        :url="item.link.url"
        class="mx-4"
      />
    </ul>
    <div @keydown.esc="drawer = false" v-show="drawer" class="overlay-wrapper">
      <div @click="drawer = false" class="overlay" tabindex="0"></div>
    </div>
  </nav>
</template>

<script>
export default {
  data: (instance) => ({
    drawer: false,
    menuItems: [],
    activeUser: instance.activeUser,
  }),
  created() {
    this.$watch(
      () => this.activeUser,
      () => {
        this.activeUser && this.menuItems.length === 0 && this.fetchMenuData();
      },
      // fetch the data when the view is created and the data is
      // already being observed.
      { immediate: true }
    );
  },
  methods: {
    async fetchMenuData() {
      const MENU_PARAMS = new URLSearchParams({
        version: STORYBLOK_VERSION_PARAM,
      }).toString();
      const PAGE_SLUG = "/navigation";

      const { data } = await useStoryblok(`${PAGE_SLUG}?${MENU_PARAMS}`).json();
      this.menuItems = data.value.story.content.menu;
    },
  },
  mounted() {
    // Close navigation on route change.
    this.$watch(
      () => this.$route.path,
      () => {
        this.drawer = false;
      }
    );
  },
};
</script>

<style lang="scss" scoped>
.nav {
  display: flex;
  position: sticky;
  padding-left: 16px;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  top: 0;
  z-index: 10;
  box-shadow: 0 2px 4px -1px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)),
    0 4px 5px 0 var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)),
    0 1px 10px 0 var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.12));

  &__mobile {
    ul {
      transform: translateX(-110%);
      transition-duration: 0.2s;
      transition-property: box-shadow, transform, visibility, width, height,
        left, right, top, bottom;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      will-change: transform;
      position: fixed;
      left: 0;
      top: 80px;
      flex-direction: column;
      width: 256px;
      bottom: 0px;
      height: calc((100vh - 80px) - 0px);
      padding-top: 8px;
      box-shadow: 0 8px 10px -5px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)),
        0 16px 24px 2px
          var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)),
        0 6px 30px 5px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.12));

      &.open {
        transform: translateX(0);
      }
    }
  }

  ul {
    list-style: none;
    display: flex;
  }
}

.logo-focusable {
  svg {
    // Add margin to give space to the outline.
    margin: 2px;
  }
  &:focus-visible {
    outline-offset: 0;
    text-decoration: none;
    outline: none;
    svg {
      border-radius: 4px;
      outline: solid 2px rgb(var(--v-theme-secondary));
    }
  }
}
.visually-hidden-focusable {
  a {
    color: #fff;
  }
  &:not(:focus):not(:focus-within) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }
}

.mobileNav {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  position: relative;
}

.overlay {
  top: 80px;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  opacity: 0.5;
  background-color: #000;
}

.overlay-wrapper {
  transition-property: opacity;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
}
</style>
