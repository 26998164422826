import { createApp } from "vue";
import App from "./App.vue";
import { StoryblokVue } from "@storyblok/vue";
import router from "./router";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { ponAccessabilityTheme } from "./theme.js";
import i18n from "./i18n";

// Okta
import OktaVue from "@okta/okta-vue";
import { oktaAuth } from "./okta";

import "./assets/styles/variables.scss";

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

import Grid from "./components/Grid.vue";
import Teaser from "./components/Teaser.vue";
import Feature from "./components/Feature.vue";
import PageBody from "./components/pageContent/PageBody.vue";
import DownloadButton from "./components/pageContent/DownloadButton.vue";
import Button from "./components/pageContent/Button.vue";
import FeaturedItem from "./components/pageContent/FeaturedItem.vue";
import PageImage from "./components/pageContent/PageImage.vue";
import DosDonts from "./components/pageContent/DosDonts.vue";
import TwoColumnsGrid from "./components/pageContent/TwoColumnsGrid.vue";

import "./assets/styles/styles.scss";
import "@mdi/font/css/materialdesignicons.css";

const app = i18n(createApp(App));

Sentry.init({
  app,
  dsn: "https://625781bd4ad440e7b8100d338d374c5a@o230677.ingest.sentry.io/4504553359409152",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["localhost", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
});

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: "ponAccessabilityTheme",
    themes: {
      ponAccessabilityTheme,
    },
  },
});

app.use(StoryblokVue);

app.component("Grid", Grid);
app.component("Teaser", Teaser);
app.component("Feature", Feature);
app.component("Page body", PageBody);
app.component("Download button", DownloadButton);
app.component("Button", Button);
app.component("featuredItem", FeaturedItem);
app.component("pageImage", PageImage);
app.component("dosDonts", DosDonts);
app.component("twoColumnsGrid", TwoColumnsGrid);

app.use(vuetify).use(router).use(OktaVue, {
  oktaAuth,
});

app.mount("#app");
