export function capitaliseString(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function orderPages(path, data) {
  const items = [];

  // Loop over all categories and set the array to the correct order.
  // Filter out resolved intervention.userGroups relations.
  data.rels
    .filter(
      (category) => category.full_slug.startsWith("user-groups/") !== true
    )
    .map((category) => {
      items.push({
        name: category.name,
        order: parseInt(category.content.order),
        uuid: category.uuid,
        children: [],
      });
    });
  items.sort((a, b) => a.order - b.order);

  data.stories.map((story) => {
    const activePage = path.includes(story.full_slug) ? true : false;

    // Get the index of the object with the same category.
    const index = items.findIndex(
      (el) => el.uuid === story.content?.locationCategory
    );

    // Get all userGroup data.
    const userGroups = [];
    if (story.content.userGroups) {
      story.content.userGroups.map((userGroup) => {
        data.rels.map((rel) => {
          if (rel.uuid === userGroup) {
            userGroups.push({
              name: rel.name,
              id: rel.uuid,
            });
          }
        });
      });
    }

    // If an item has no locationCategory, skip.
    if (index !== -1) {
      // Only add the menu item to the items array.
      items[index].children.push({
        name: story.name,
        target: "/" + story.full_slug,
        active: activePage,
        visible: true,
        userGroups: userGroups || [],
        uuid: story.uuid,
      });
    }
  });

  return items;
}

export const saveDataToLocalStorage = (entity, key, object) => {
  if ("localStorage" in window) {
    localStorage.setItem(`toolkit-${entity}-${key}`, JSON.stringify(object));
  }
};

export const readDataFromLocalStorage = (entity, key) => {
  if ("localStorage" in window) {
    const data = localStorage.getItem(`toolkit-${entity}-${key}`);
    if (data !== undefined && data !== null) {
      return JSON.parse(data);
    }
  }
};

export const removeDataFromLocalStorage = (entity, key) => {
  if ("localStorage" in window) {
    localStorage.removeItem(`toolkit-${entity}-${key}`);
  }
};
