import { APPRAISAL_API_ROOT } from "../config/constants";
import { oktaAuth } from "../auth/oktaAuth";

/**
 * @param {Object} [options] `Fetch` options Object
 *
 * @return {Promise} Resolves to options
 */
async function addOktaBearerToFetchOptions(options) {
  const allOptions = { ...options };
  const accessToken = await oktaAuth.getAccessToken();
  const headerValue = `Bearer ${accessToken}`;
  const headerName = "Authorization";

  // Other headers might already exist
  if (allOptions.headers) {
    // headers can be `Headers`
    if (allOptions.headers.set) {
      allOptions.headers.set(headerName, headerValue);
    } else {
      // or simple JSON
      allOptions.headers[headerName] = headerValue;
    }
  } else {
    // Just add simple JSON instead
    allOptions.headers = {
      [headerName]: headerValue,
    };
  }

  // Original object is modified, but returning to be polite
  return allOptions;
}

/**
 * GET data from the API.
 *
 * @param {string} endpoint // The endpoint to fetch.
 * @param {object} options // Options to send with the request.
 */
export async function getDataFromApi(endpoint, options = {}) {
  const authOptions = await addOktaBearerToFetchOptions(options);

  try {
    const result = await fetch(`${APPRAISAL_API_ROOT}${endpoint}`, {
      method: "GET",
      ...authOptions,
    });

    if (!result.ok) {
      throw Error("There was an error: ", result.statusText);
    }

    return result;
  } catch (error) {
    console.error("The error given is: ", error);
  }
}

/**
 * Post data from the API.
 *
 * @param {string} endpoint // The endpoint to fetch.
 * @param {object} options // Options to send with the request.
 * @param {object} body // Body to send with the request.
 */
export async function postDataToApi(endpoint, options = {}, body = {}) {
  const authOptions = await addOktaBearerToFetchOptions(options);

  try {
    const result = await fetch(`${APPRAISAL_API_ROOT}${endpoint}`, {
      method: "POST",
      ...authOptions,
      body: JSON.stringify(body),
    });

    if (!result.ok) {
      throw Error("There was an error: ", result.statusText);
    }

    return result;
  } catch (error) {
    console.error("The error given is: ", error);
  }
}
