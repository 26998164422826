<script setup>
import { orderPages } from "./../utils/utils";
import { useStoryblok } from "../hooks/useStoryblok";
import { STORYBLOK_VERSION_PARAM } from "../config/constants";
</script>

<script>
export default {
  created() {
    // watch the params of the route to fetch the data.
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchDataAndRedirect();
      },
      // fetch the data when the view is created and the data is
      // already being observed.
      { immediate: true }
    );
  },
  methods: {
    async fetchDataAndRedirect() {
      const { contentType, contentCategory } = this.$route.meta;
      const path = window.location.pathname;

      const options = new URLSearchParams({
        version: STORYBLOK_VERSION_PARAM,
        starts_with: path.split("/")[1], // Get the category.
        content_type: contentType,
        resolve_relations: contentType + "." + contentCategory,
      });
      const { data } = await useStoryblok(`/?${options.toString()}`).json();

      let redirect = "/";

      if ("checklist" === path.split("/")[1]) {
        const items = orderPages(path, data.value);
        // Check if there is an underlying page.
        if (items.length) {
          redirect = items[0].children[0].target;
        }
      } else {
        redirect = data.value.stories[0].full_slug;
      }
      // Redirect the user to the underlying page.
      this.$router.replace({ path: redirect });
    },
  },
};
</script>
