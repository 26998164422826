<script setup>
import i18n from "i18next";
import { useStoryblok } from "../hooks/useStoryblok";
import {
  orderPages,
  readDataFromLocalStorage,
  capitaliseString,
} from "./../utils/utils";
import PageSidebar from "../components/general/PageSidebar.vue";
import Appraisal from "../components/checklist/Appraisal.vue";
import Title from "../components/general/Title.vue";
import SiteSelector from "../components/SiteSelector.vue";
import { STORYBLOK_VERSION_PARAM } from "../config/constants";
import { getAppraisalData } from "../dao/appraisal";
</script>

<template>
  <Title :title="page.story.name" />
  <v-container>
    <v-alert
      v-if="error"
      type="error"
      :title="i18next.t('errorTitle')"
      :text="i18next.t('loadingError')"
    />
    <v-progress-circular
      v-else-if="isFetching || isFetchingUserGroups || isFetchingChecklistMenu"
      indeterminate
    />
    <div v-else>
      <v-row class="mb-md-4">
        <v-col cols="12">
          <p class="input-label">{{ $t("filterChecklist") }}</p>
          <div class="filterbox mx-0 py-0 inline-mobile">
            <div
              class="user-group"
              v-for="userGroup in userGroups"
              :key="userGroup.id"
            >
              <label
                :class="{
                  selected: selectedUserGroups.includes(userGroup.id),
                }"
              >
                <input
                  type="checkbox"
                  v-model="selectedUserGroups"
                  :id="userGroup.name"
                  :value="userGroup.id"
                  :name="`Filter checklist to ${userGroup.name}`"
                />
                <span class="badge">
                  <i
                    class="mdi-check mdi v-icon notranslate v-theme--ponAccessabilityTheme v-icon--size-default"
                    aria-hidden="true"
                  ></i>
                  {{ userGroup.name }}
                  <img
                    class="user-group__icon ml-1"
                    :src="userGroup.icon"
                    alt=""
                /></span>
              </label>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" class="d-flex flex-column">
          <div :class="{ 'sections-scrollable': $vuetify.display.mdAndUp }">
            <h2 data-cy="heading">
              {{
                capitaliseString(
                  $t("checklist").split("/")[0].replaceAll("-", " ")
                )
              }}
            </h2>
            <SiteSelector class="d-sm-none" />
            <v-btn
              class="mt-4 d-sm-none text-none"
              color="primary"
              @click.stop="openChecklistMenu = !openChecklistMenu"
            >
              {{ $t("toggleChecklistMenu") }}
            </v-btn>
            <v-navigation-drawer
              class="d-sm-none px-2 py-2 w-75"
              v-model="openChecklistMenu"
              temporary
            >
              <PageSidebar
                :title="i18n.t('checklist')"
                :items="checklistItems"
                :isChecklistPage="true"
                :isInDrawer="true"
              />
            </v-navigation-drawer>
            <div class="hidden-xs">
              <PageSidebar
                :title="i18n.t('checklist')"
                :items="checklistItems"
                :isChecklistPage="true"
                :isInDrawer="false"
              />
            </div>

            <router-link
              to="/report"
              class="text-primary text-center text-decoration-none"
              ><v-btn
                class="mt-4 text-none w-50"
                variant="outlined"
                elevation="0"
              >
                {{ $t("fullReport") }}
                <v-icon icon="mdi-chart-bar" size="small" class="ml-2" />
              </v-btn>
            </router-link>
          </div>
        </v-col>

        <v-col cols="12" sm="9" class="d-flex">
          <v-card
            elevation="0"
            class="w-100 py-6 px-3"
            :class="{ 'sections-scrollable': $vuetify.display.mdAndUp }"
            id="page__content"
          >
            <v-row>
              <v-col cols="12" md="7">
                <v-card-subtitle class="pt-3 font-weight-bold card__subtitle">
                  {{ locationName }}
                </v-card-subtitle>
                <v-card-title>
                  <h1 class="text-h5 font-weight-bold">
                    {{ page.story.name }}
                  </h1>
                </v-card-title>
              </v-col>
              <v-col cols="12" md="5" class="order-first order-md-last pt-4">
                <v-chip-group column class="justify-end">
                  <v-chip
                    v-for="relatedUserGroup in relatedUserGroupsData"
                    :key="relatedUserGroup.id"
                    :ripple="false"
                    class="user-group user-group--small"
                    disabled
                    size="x-small"
                  >
                    {{ relatedUserGroup.name }}
                    <img
                      :src="relatedUserGroup.icon"
                      alt=""
                      class="user-group__icon--small ml-1"
                    />
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-card-text class="py-0">
                  <StoryblokComponent
                    v-for="blok in page.story.content.body"
                    :blok="blok"
                    :key="blok._uid"
                  />
                </v-card-text>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-card-text>
                  <Appraisal
                    :refreshChecklistMenu="fetchChecklistMenu"
                    :pageData="page"
                    :locationName="locationName"
                    :appraisal="
                      appraisalData.find(
                        (appraisal) =>
                          appraisal.interventionId === page.story.uuid
                      )
                    "
                  />
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    selectedUserGroups: [],
    checklistItems: [],
    relatedUserGroupsData: [],
    page: null,
    userGroups: [],
    isFetching: true,
    isFetchingUserGroups: true,
    isFetchingChecklistMenu: true,
    error: false,
    errorUserGroups: false,
    errorChecklistMenu: false,
    appraisalData: [],
    selectedOpCoId: null,
    selectedSiteId: null,
    openChecklistMenu: false,
    locationName: "",
  }),
  created() {
    const selectedOpCoId = readDataFromLocalStorage("selectedOpCo", "uuid");
    const selectedSiteId = readDataFromLocalStorage("selectedSite", "uuid");
    if (
      typeof selectedOpCoId === "undefined" ||
      typeof selectedSiteId === "undefined"
    ) {
      // Redirect user to the site selection page when no OpCo/Site has been selected.
      this.$router.push({
        path: "/site-select",
        query: { redirectTo: this.$route.fullPath },
      });
    }
    this.selectedOpCoId = selectedOpCoId;
    this.selectedSiteId = selectedSiteId;
    // watch the params of the route to fetch the data again.
    this.$watch(
      () => this.$route.params,
      () => {
        this.isFetching = true;
        this.isFetchingUserGroups = true;
        this.isFetchingChecklistMenu = true;
        this.fetchChecklistMenu(selectedOpCoId, selectedSiteId);
        this.fetchPageData();
      },
      // fetch the data when the view is created and the data is
      // already being observed.
      { immediate: true }
    );
  },
  watch: {
    selectedUserGroups: function () {
      this.isFetching = true;
      this.isFetchingUserGroups = true;
      this.isFetchingChecklistMenu = true;
      this.fetchChecklistMenu();
      this.fetchPageData();
    },
  },
  methods: {
    selectUserGroup(e) {
      this.selectedUserGroups.push(e.target.value);
    },
    async fetchChecklistMenu() {
      const path = window.location.pathname;

      let checklistOptions = new URLSearchParams({
        version: STORYBLOK_VERSION_PARAM,
        starts_with: path.split("/")[1], // Get the category.
        content_type: "intervention",
        resolve_relations: "intervention.locationCategory",
        per_page: 100,
      });

      if (this.selectedUserGroups.length > 0) {
        checklistOptions.append(
          "filter_query[userGroups][any_in_array]",
          `${this.selectedUserGroups.toString()}`
        );
      }

      const { isFetching, error, data } = await useStoryblok(
        `/?${checklistOptions}`
      ).json();

      const checklistItems = orderPages(path, data.value);

      await getAppraisalData(this.selectedOpCoId, this.selectedSiteId).then(
        (res) => {
          // Loop through locations.
          checklistItems.forEach((checklistItem) => {
            // Loop through interventions.
            checklistItem.children.map((intervention) => {
              const appraisalData = res.rows.find(
                (appraisal) => appraisal.interventionId === intervention.uuid
              );

              if (appraisalData) {
                // Add current situation.
                intervention.situation = appraisalData.currentSituation;
              } else {
                intervention.situation = null;
              }
            });
          });

          this.appraisalData = res.rows;
        }
      );

      this.isFetchingChecklistMenu = isFetching;
      this.errorChecklistMenu = error;
      // Get data for menu items and icons if applicable.
      this.checklistItems = checklistItems;
    },
    async fetchPageData() {
      // Get data for the current page.
      const path = window.location.pathname;
      const interventionOptions = new URLSearchParams({
        version: STORYBLOK_VERSION_PARAM,
        resolve_relations:
          "intervention.locationCategory,intervention.userGroups",
      });
      const { isFetching, error, data } = await useStoryblok(
        `${path}/?${interventionOptions.toString()}`
      ).json();

      let interventionUserGroups = [];
      const userGroups = [];
      const relatedUserGroupsData = [];

      // Check if there are userGroups added to the intervention.
      if (
        data.value.story.content?.userGroups &&
        data.value.story.content?.userGroups.length > 0
      ) {
        interventionUserGroups = data.value.story.content?.userGroups.map(
          (userGroup) => data.value.rels.find((rel) => rel.uuid === userGroup)
        );
      }

      // Get all userGroups.
      const userGroupsOptions = new URLSearchParams({
        version: STORYBLOK_VERSION_PARAM,
        content_type: "userGroup",
      });

      const {
        isFetching: isFetchingUserGroups,
        error: errorUserGroups,
        data: userGroupsData,
      } = await useStoryblok(`/?${userGroupsOptions.toString()}`).json();

      userGroupsData.value.stories.map((story) => {
        userGroups.push({
          icon: story.content.icon.filename,
          name: story.name,
          id: story.uuid,
        });
      });

      // Map the intervention data to the fetched userGroups.
      interventionUserGroups.map((interventionUserGroup) => {
        userGroups.map((userGroup) => {
          if (userGroup.name === interventionUserGroup.name) {
            relatedUserGroupsData.push(userGroup);
          }
        });
      });

      const locationName = data.value.rels.find((rel) => {
        return rel.uuid === data.value.story.content.locationCategory;
      });

      this.isFetching = isFetching;
      this.isFetchingUserGroups = isFetchingUserGroups;
      this.error = error;
      this.errorUserGroups = errorUserGroups;
      this.interventionUserGroups = interventionUserGroups;
      this.relatedUserGroupsData = relatedUserGroupsData;
      this.userGroups = userGroups;
      this.page = data;
      this.locationName = locationName.name;
    },
  },
};
</script>

<style scoped lang="scss">
.user-group {
  &--small {
    font-size: 14px !important;
  }
  &__icon {
    height: 32px;
    width: 32px;
    &--small {
      height: 20px;
      width: 20px;
    }
  }
}
.v-chip--disabled {
  opacity: 1;
}

.sections-scrollable {
  height: 65vh;
  overflow-y: auto;
  padding-right: 12px;
  scrollbar-width: thin;
}

.sections-scrollable::-webkit-scrollbar {
  width: 6px;
}

.sections-scrollable::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 3px;
}

.filterbox {
  flex-wrap: wrap;
  white-space: normal;
  display: flex;
  max-width: 100%;
  min-width: 0;
  overflow-x: auto;
  padding: 4px 0;

  @media (max-width: 599px) {
    flex-wrap: nowrap;
    overflow: scroll;
  }

  .user-group {
    label {
      margin: 4px 8px 4px 0;
      height: 38px;
      padding: 0 14px;
      background: #e4e4e4;
      border-radius: 9999px;
      align-items: center;
      cursor: pointer;
      display: inline-flex;
      font-weight: 400;
      max-width: 100%;
      overflow: hidden;
      position: relative;
      text-decoration: none;
      white-space: nowrap;
      vertical-align: middle;

      &.selected {
        background: #466376;
        .badge {
          color: #fff;
          img {
            filter: grayscale(1) invert(1);
          }
          i {
            display: flex;
            margin-right: 8px;
          }
        }
      }

      input[type="checkbox"] {
        position: absolute;
        clip: rect(0 0 0 0);
      }
    }

    .badge {
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        display: none;
        color: #fff;
      }

      @media (max-width: 599px) {
        padding: 0 14px;
      }
    }

    &:focus-within {
      label {
        background: #d2d2d2;
        outline: solid 2px rgb(var(--v-theme-secondary));
        border: none;
        &::after {
          border: none;
        }

        &.selected {
          background: #466376;
        }
      }
    }
  }
}
</style>
