import { OktaAuth } from "@okta/okta-auth-js";

const oktaConfig = {
  clientId: import.meta.env.VITE_OKTA_CLIENT_ID,
  issuer: import.meta.env.VITE_OKTA_ISSUER,
  redirectUri: window.location.origin + "/login/callback",
  scopes: ["openid", "profile", "email"],
};

export const oktaAuth = new OktaAuth(oktaConfig);
