<script setup>
defineProps({ title: String, url: String });
</script>

<template>
  <li>
    <router-link
      :to="url"
      class="text-decoration-none font-weight-bold d-flex align-center rounded outline-focus"
      @mouseover="isHovering = true"
      @mouseout="isHovering = false"
      :class="[
        isHovering ? 'text-secondary' : '',
        isActive ? 'text-secondary text-decoration-underline' : '',
        !isActive && !isHovering ? 'text-white' : '',
      ]"
      :name="`Link to ${title} page`"
      tabindex="0"
    >
      {{ title }}
    </router-link>
  </li>
</template>

<script>
export default {
  data: () => ({
    isHovering: false,
    isActive: false,
  }),
  created() {
    // watch the params of the route to fetch the data again.
    this.$watch(
      () => this.$route.params,
      () => {
        this.isActive = window.location.pathname.includes(this.url);
      },
      // fetch the data when the view is created and the data is
      // already being observed.
      { immediate: true }
    );
  },
};
</script>

<style scoped lang="scss">
li {
  padding: 12px 16px;
}
</style>
