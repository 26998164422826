<script setup>
defineProps({ buttonTitle: String, buttonUrl: String });
</script>

<template>
  <!-- Add the properties passed down to the component in the v-bind. -->
  <v-btn v-if="isExternal" :href="buttonUrl" v-bind="$attrs" role="button">
    {{ buttonTitle }}
  </v-btn>
  <v-btn v-else :to="buttonUrl" v-bind="$attrs" role="button">
    {{ buttonTitle }}
  </v-btn>
</template>

<script>
export default {
  computed: {
    isExternal() {
      return this.buttonUrl.startsWith("https");
    },
  },
};
</script>
