const en = {
  translation: {
    accessibilityToolkit: "Accessibility Toolkit",
    currentSituation: "Current situation",
    comments: "Comments",
    doesnotapply: "Does not apply",
    todo: "To do",
    done: "Done",
    save: "Save",
    change: "Change",
    copyright: "© {year} Pon - version {gitVersion}",
    help: "Need help or do you want to give us feedback? Please contact {email} by clicking this link",
    helpEmail: "inclusion@pon.com",
    skip: "Skip to main content",
    menu: "Menu",
    checklist: "Checklist",
    filterChecklist: "Filter checklist steps by user groups:",
    hi: "Hi, {name}!",
    nowAppraising: "You are now appraising",
    startMessage:
      "Before you start, you have to check in on your location by selecting the Pon OpCo and location below.",
    opcoSelect: "Select OpCo",
    siteSelect: "Select Site",
    submit: "Submit",
    errorTitle: "Error",
    successfulSubmitTitle: "Success",
    successfulSubmitText: "Appraisal submitted successfully",
    lastUpdated:
      "The appraisal for this intervention was updated by {name} on {date}",
    loadingError: "Something went wrong when loading this page",
    dos: "Do's",
    donts: "Don'ts",
    disclaimer: "Please do not submit any kind of {sensitiveInformation}.",
    sensitiveInformation: "sensitive information",
    report: "Report",
    reportFor: "Report for {opco} {location}",
    intervention: "Intervention",
    location: "Location",
    back: "Back",
    fullReport: "Full report",
    toggleChecklistMenu: "Select intervention",
  },
};

export default en;
