<script setup>
import moment from "moment";
</script>

<template>
  <v-footer class="footer bg-lightGray">
    <v-container class="d-md-flex justify-md-space-between">
      <p>
        <i18next :translation="$t('copyright')">
          <template #year>
            {{ year }}
          </template>
          <template #gitVersion>
            {{ gitVersion }}
          </template>
        </i18next>
      </p>
      <a
        href="mailto:inclusion@pon.com"
        class="text-black text-decoration-none outline-focus"
      >
        <i18next :translation="$t('help')">
          <template #email>
            <b>{{ $t("helpEmail") }}</b>
          </template>
        </i18next>
      </a>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    // __GIT_HASH__ is set in the vite.config.js
    // eslint-disable-next-line no-undef
    gitVersion: __GIT_HASH__,
    year: moment().year(),
  }),
};
</script>

<style lang="scss">
.footer {
  font-size: 14px;
  justify-content: center;

  @media (min-width: 960px) {
    height: 60px;
    max-height: 60px;
  }

  .v-container {
    @media (max-width: 959px) {
      padding: 0;
    }
  }
}
</style>
