<script setup>
import { STORYBLOK_VERSION_PARAM } from "../config/constants";
import { useStoryblok } from "../hooks/useStoryblok";
import { saveDataToLocalStorage } from "./../utils/utils";
import Title from "../components/general/Title.vue";
</script>

<template>
  <Title :title="$t('checklist')" />
  <v-container>
    <v-row>
      <v-col cols="12" class="d-flex">
        <v-card elevation="0" class="w-100 py-6 px-3" id="page__content">
          <v-row>
            <v-col cols="12">
              <v-card-title class="text-h5 font-weight-bold">
                <i18next :translation="$t('hi')">
                  <template #name>
                    {{ name }}
                  </template>
                </i18next>
              </v-card-title>
              <v-card-text class="py-0">
                {{ $t("startMessage") }}
                <div class="pt-4 d-flex flex-column">
                  <label for="opcoSelect" class="input-label">{{
                    $t("opcoSelect")
                  }}</label>
                  <select v-model="selectedOpCo" id="opcoSelect">
                    <option :value="null" disabled selected>
                      {{ $t("opcoSelect") }}
                    </option>
                    <option
                      v-for="(opco, index) in opcos"
                      :key="index"
                      :value="opco"
                    >
                      {{ opco.name }}
                    </option>
                  </select>

                  <label for="selectedSite" class="input-label">{{
                    $t("siteSelect")
                  }}</label>
                  <select
                    id="selectedSite"
                    v-model="selectedSite"
                    :disabled="selectedOpCo === null"
                  >
                    <option :value="null" disabled selected>
                      {{ $t("siteSelect") }}
                    </option>
                    <option
                      v-for="(site, index) in sites"
                      :key="index"
                      :value="site.uuid"
                    >
                      {{ site.name }}
                    </option>
                  </select>

                  <div>
                    <v-btn
                      class="text-none elevation-0 bg-primary font-weight-bold mt-4"
                      @click="setAppraisalSite()"
                      :loading="loading"
                      :disabled="selectedSite === null"
                    >
                      {{ $t("submit") }}
                    </v-btn>
                  </div>
                </div>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      opcos: [],
      sites: [],
      selectedOpCo: null,
      selectedSite: null,
    };
  },
  created() {
    this.$auth.getUser().then((response) => (this.name = response.name));
    this.fetchOpCoData();
  },
  watch: {
    selectedOpCo: function () {
      this.sites = this.selectedOpCo.sites;
    },
  },
  computed: {
    selectedOptionText() {
      const selectedOption = this.opcos.find(
        (option) => option.value === this.selectedOpCo
      );
      return selectedOption ? selectedOption.text : "";
    },
  },
  methods: {
    async fetchOpCoData() {
      const perPage = 5;
      let data = [];
      let stories = [];
      let rels = [];

      let currentPage = 1;
      let totalPages = 1;
      let totalEntries = 0;

      while (currentPage <= totalPages) {
        let opcoOptions = new URLSearchParams({
          version: STORYBLOK_VERSION_PARAM,
          starts_with: "opcos",
          content_type: "opco",
          resolve_relations: "opco.sites",
          per_page: perPage,
          page: currentPage,
          sort_by: "name:asc",
        });

        const response = useStoryblok(`/?${opcoOptions}`);
        const json = await response.json();
        const results = json.data.value;

        results.stories.map((story) => {
          return stories.push(story);
        });

        results.rels.map((rel) => {
          return rels.push(rel);
        });

        totalEntries = json.response.value.headers.get("Total");
        totalPages = Math.ceil(totalEntries / perPage);

        currentPage++;
      }

      data = {
        rels,
        stories,
      };

      const opcoItems = data.stories
        .filter(
          (story) =>
            Object.prototype.hasOwnProperty.call(story.content, "sites") &&
            story.content.sites.length > 0
        )
        .map((story) => {
          const sites = story.content.sites.map((site) => ({ uuid: site }));
          return { name: story.name, uuid: story.uuid, sites };
        });

      data.rels.map((site) => {
        // Get the index of the object with the same category.
        opcoItems.map((opco) => {
          opco.sites.map((opcoSite) => {
            if (opcoSite.uuid === site.uuid) {
              opcoSite.name = site.name;
            }
          });
        });
      });

      this.opcos = opcoItems;
    },
    async setAppraisalSite() {
      saveDataToLocalStorage("selectedOpCo", "uuid", this.selectedOpCo.uuid);
      saveDataToLocalStorage("selectedSite", "uuid", this.selectedSite);

      this.$router.push({
        path: this.$route.query.redirectTo,
      });
    },
  },
};
</script>

<style scoped lang="scss">
select {
  border-bottom: 1px solid #686868;
  color: rgba(0, 0, 0, 0.87);
  background-color: #e7e7e7;
  width: 100%;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 8px);
  background-position-y: 12px;
  @media (min-width: 960px) {
    max-width: 50%;
  }
  letter-spacing: 0.009375em;
  opacity: var(--v-high-emphasis-opacity);
  padding: 16px;
  margin-bottom: 22px;

  &:disabled {
    opacity: 0.5;
  }
}
</style>
