export const STORYBLOK_PROXY = import.meta.env.VITE_CF_CONTENT_PROXY;
export const CONTENT_ROOT = "/cdn/stories";
export const API_ENDPOINT = STORYBLOK_PROXY + CONTENT_ROOT;
export const STORYBLOK_VERSION_PARAM = import.meta.env
  .VITE_STORYBLOK_VERSION_PARAM;
export const APPRAISAL_API_ROOT = import.meta.env.VITE_API_ROOT;
export const ALERT_DURATION = 7000;
export const DATE_FORMAT = "DD-MM-YYYY";
export const TO_DO = "todo";
export const DOES_NOT_APPLY = "doesnotapply";
export const DONE = "done";
export const INTERVENTION_STATUS = {
  TO_DO: "orange",
  DONE: "green",
  DOES_NOT_APPLY: "gray",
};
