<script setup>
import {
  INTERVENTION_STATUS,
  DONE,
  TO_DO,
  DOES_NOT_APPLY,
} from "../../config/constants";
import SiteSelector from "../../components/SiteSelector.vue";

defineProps({
  title: String,
  items: Object,
  isChecklistPage: Boolean,
  isInDrawer: Boolean,
});
</script>

<template>
  <SiteSelector :class="isInDrawer && 'hidden-xs'" v-show="isChecklistPage" />
  <v-divider class="mt-6 mb-2 hidden-xs divider"></v-divider>

  <div class="v-list" aria-label="List of pages">
    <div v-for="item in items" :key="item.name">
      <div class="v-list-item density py-3 pl-0">
        <p class="font-weight-bold v-list-item-title">{{ item.name }}</p>
      </div>
      <ul role="tablist">
        <li
          v-for="child in item.children"
          :key="child.name"
          :aria-label="`Intervention for ${child.name}`"
          v-show="child.visible !== false"
          role="tab"
        >
          <router-link
            :active="child.active"
            :to="child.target"
            :class="{
              active: child.active,
              [`${getStatusColor(child.situation)} intervention-status`]:
                typeof child.situation !== 'undefined',
            }"
            tabindex="0"
            class="v-list-item v-list-item--link density outline-focus py-3 rounded mx-1"
            >{{ child.name }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
function getStatusColor(situation) {
  if (situation === TO_DO) {
    return INTERVENTION_STATUS.TO_DO;
  } else if (situation === DONE) {
    return INTERVENTION_STATUS.DONE;
  } else if (situation === DOES_NOT_APPLY) {
    return INTERVENTION_STATUS.DOES_NOT_APPLY;
  } else {
    return "none";
  }
}
</script>

<style scoped>
a {
  color: inherit;
}

.active,
a:hover {
  background-color: #e4e4e4 !important;
}

.density {
  min-height: 48px;
}
</style>
