<script setup>
import i18next from "i18next";
import { STORYBLOK_VERSION_PARAM } from "../config/constants";
import { useStoryblok } from "../hooks/useStoryblok";
import FeaturedItem from "./../components/pageContent/FeaturedItem.vue";
import Title from "../components/general/Title.vue";

const HOME_PARAMS = new URLSearchParams({
  version: STORYBLOK_VERSION_PARAM,
}).toString();
const PAGE_SLUG = "/home";

const { error, data } = useStoryblok(`${PAGE_SLUG}?${HOME_PARAMS}`).json();
</script>

<template>
  <Title />
  <v-container id="homeContainer">
    <v-row align="center">
      <v-col cols="12" md="9">
        <v-alert
          v-if="error"
          type="error"
          :title="i18next.t('errorTitle')"
          :text="i18next.t('loadingError')"
        />
        <div v-else>
          <h1 class="text-primary">{{ data.story.content.title }}</h1>
          <p class="text-primary">
            {{ data.story.content.description }}
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row class="pt-4">
      <FeaturedItem
        v-for="blok in data.story.content.body"
        :key="blok._uid"
        :blok="blok"
      />
    </v-row>
  </v-container>
</template>
